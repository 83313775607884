import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { useEffect } from 'react';
import useCurrentState from '../utils/use-current-state';
import { useGeistUIContext } from '../utils/use-geist-ui-context';
import { getId } from '../utils/collections';
var defaultToast = {
  delay: 2000
};
var destoryStack = [];
var maxDestoryTime = 0;
var destoryTimer;

var useToasts = function useToasts() {
  var _useGeistUIContext = useGeistUIContext(),
      updateToasts = _useGeistUIContext.updateToasts,
      toastHovering = _useGeistUIContext.toastHovering,
      toasts = _useGeistUIContext.toasts;

  var _useCurrentState = useCurrentState(toastHovering),
      _useCurrentState2 = _slicedToArray(_useCurrentState, 3),
      setHovering = _useCurrentState2[1],
      hoveringRef = _useCurrentState2[2];

  useEffect(function () {
    return setHovering(toastHovering);
  }, [toastHovering]);

  var destoryAll = function destoryAll(delay, time) {
    /* istanbul ignore next */
    if (time <= maxDestoryTime) return;
    clearTimeout(destoryTimer);
    maxDestoryTime = time;
    destoryTimer = window.setTimeout(function () {
      /* istanbul ignore next */
      updateToasts(function (currentToasts) {
        if (destoryStack.length < currentToasts.length) {
          return currentToasts;
        }

        destoryStack = [];
        return [];
      });
      clearTimeout(destoryTimer);
    }, delay + 350);
  };

  var setToast = function setToast(toast) {
    var id = "toast-".concat(getId());
    var delay = toast.delay || defaultToast.delay;

    var cancel = function cancel(id, delay) {
      updateToasts(function (currentToasts) {
        return currentToasts.map(function (item) {
          if (item.id !== id) return item;
          return _extends({}, item, {
            willBeDestroy: true
          });
        });
      });
      destoryStack.push(id);
      destoryAll(delay, performance.now());
    };

    updateToasts(function (currentToasts) {
      var newToast = _extends({}, toast, {
        id: id,
        delay: delay,
        cancel: function (_cancel) {
          function cancel() {
            return _cancel.apply(this, arguments);
          }

          cancel.toString = function () {
            return _cancel.toString();
          };

          return cancel;
        }(function () {
          return cancel(id, delay);
        })
      });

      return [].concat(_toConsumableArray(currentToasts), [newToast]);
    });

    var hideToast = function hideToast(id, delay) {
      var hideTimer = window.setTimeout(function () {
        if (hoveringRef.current) {
          hideToast(id, delay);
          return clearTimeout(hideTimer);
        }

        cancel(id, delay);
        clearTimeout(hideTimer);
      }, delay);
    };

    hideToast(id, delay);
  };

  return [toasts, setToast];
};

export default useToasts;