import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import { useEffect, useRef, useState } from 'react';
var defaultOptions = {
  scrollLayer: false
};
var elementStack = new Map();

var isIos = function isIos() {
  /* istanbul ignore next */
  if (typeof window === 'undefined' || !window.navigator) return false;
  return /iP(ad|hone|od)/.test(window.navigator.platform);
};

var touchHandler = function touchHandler(event) {
  if (event.touches && event.touches.length > 1) return true;
  event.preventDefault();
  return false;
};

var useBodyScroll = function useBodyScroll(elementRef, options) {
  /* istanbul ignore next */
  if (typeof document === 'undefined') return [false, function (t) {
    return t;
  }];
  var elRef = elementRef || useRef(document.body);

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      hidden = _useState2[0],
      setHidden = _useState2[1];

  var safeOptions = _extends({}, defaultOptions, options || {}); // don't prevent touch event when layer contain scroll


  var isIosWithCustom = function isIosWithCustom() {
    if (safeOptions.scrollLayer) return false;
    return isIos();
  };

  useEffect(function () {
    if (!elRef || !elRef.current) return;
    var lastOverflow = elRef.current.style.overflow;

    if (hidden) {
      if (elementStack.has(elRef.current)) return;

      if (!isIosWithCustom()) {
        elRef.current.style.overflow = 'hidden';
      } else {
        document.addEventListener('touchmove', touchHandler, {
          passive: false
        });
      }

      elementStack.set(elRef.current, {
        last: lastOverflow
      });
      return;
    } // reset element overflow


    if (!elementStack.has(elRef.current)) return;

    if (!isIosWithCustom()) {
      var store = elementStack.get(elRef.current);
      elRef.current.style.overflow = store.last;
    } else {
      document.removeEventListener('touchmove', touchHandler);
    }

    elementStack["delete"](elRef.current);
  }, [hidden, elRef]);
  return [hidden, setHidden];
};

export default useBodyScroll;